import { create } from "zustand";
const projectDetails = (set, get) => ({
    assetChargeActivityData: [],
    assetChargeActivityDetailsData: [],
    assetDetails: null,
    historicDataDate: null,
    networkDiagramData: [],
    networkEdges: [],
    networkNodes: [],
    projectDetails: null,
    projectMetaData: null,
    projectName: "",
    setAssetChargeActivityData: (assetChargeActivityData) => {
        set(() => ({ assetChargeActivityData }));
    },
    setAssetChargeActivityDetailsData: (assetChargeActivityDetailsData) => {
        set(() => ({ assetChargeActivityDetailsData }));
    },
    setAssetDetails: (assetDetails) => {
        set(() => ({ assetDetails }));
    },
    setAssetsDetailsBySort: (sortBy, orderBy) => {
        const projectDetailsInfo = get().projectDetails;
        const assetsDetails = projectDetailsInfo?.AssetDetails;
        if (sortBy === "soc") {
            if (orderBy === "ASC") {
                const assetDetailsASC = [...assetsDetails].sort((objOne, objTwo) => objOne.soc - objTwo.soc);
                projectDetailsInfo.AssetDetails = assetDetailsASC;
            }
            else {
                //orderBy DESC
                const assetDetailsDESC = [...assetsDetails].sort((objOne, objTwo) => objTwo.soc - objOne.soc);
                projectDetailsInfo.AssetDetails = assetDetailsDESC;
            }
            set(() => ({
                projectDetails: projectDetailsInfo
            }));
        }
    },
    setHistoricDataDate: (historicDataDate) => {
        set(() => ({ historicDataDate }));
    },
    setNetworkDiagramData: (networkDiagramData) => {
        set(() => ({ networkDiagramData }));
    },
    setNetworkEdges: (networkEdges) => {
        set(() => ({ networkEdges }));
    },
    setNetworkNodes: (networkNodes) => {
        set(() => ({ networkNodes }));
    },
    setProjectDetails: (projectDetails) => {
        set(() => ({ projectDetails }));
    },
    setProjectMetaData: (projectMetaData) => {
        set(() => ({ projectMetaData }));
    }
});
const useProjectDetailsStore = create(projectDetails);
export default useProjectDetailsStore;
