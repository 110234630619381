import { jsx as _jsx } from "react/jsx-runtime";
import * as d3 from "d3";
import { useEffect, useRef } from "react";
const AssetTimeline = ({ assetTimelineStatus }) => {
    const svgRef = useRef(null);
    const svgWidth = 500;
    const svgHeight = 60 * assetTimelineStatus.length;
    useEffect(() => {
        if (!svgRef.current)
            return;
        // Define SVG dimensions and margins
        const svg = d3.select(svgRef.current);
        const margin = { bottom: 20, left: 20, right: 20, top: 40 };
        // Clear previous SVG content if re-rendered
        svg.selectAll("*").remove();
        // Create y-scale for spacing the events vertically
        const yScale = d3
            .scalePoint()
            .domain(d3.range(assetTimelineStatus.length).map(String))
            .range([margin.top, svgHeight - margin.bottom]);
        // Draw the vertical timeline line
        svg.append("line")
            .attr("x1", margin.left)
            .attr("y1", margin.top)
            .attr("x2", margin.left)
            .attr("y2", svgHeight - margin.bottom)
            .attr("stroke", "black")
            .attr("stroke-width", 2);
        // Render each status event
        assetTimelineStatus.forEach((item, index) => {
            const yPosition = yScale(String(index)) || 0;
            // Add circle marker
            svg.append("circle")
                .attr("cx", margin.left)
                .attr("cy", yPosition)
                .attr("r", 8)
                .attr("fill", index === 0 ? "black" : "white")
                .attr("stroke", "black")
                .attr("stroke-width", 2);
            // Add status text
            svg.append("text")
                .attr("x", margin.left + 20)
                .attr("y", yPosition - 5)
                .attr("class", "font-bold text-lg")
                .text("Asset Status");
            // Add details text
            svg.append("text")
                .attr("x", margin.left + 20)
                .attr("y", yPosition + 15)
                .attr("class", "text-gray-700")
                .text(item.details);
            // Add timestamp
            svg.append("text")
                .attr("x", svgWidth - margin.right)
                .attr("y", yPosition - 5)
                .attr("class", "text-gray-500 text-sm")
                .attr("text-anchor", "end")
                .text(item.time);
        });
    }, [assetTimelineStatus]);
    return (_jsx("div", { className: "w-full h-[320px] overflow-y-auto", children: _jsx("svg", { height: svgHeight, ref: svgRef, width: svgWidth }) }));
};
export default AssetTimeline;
